import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from './../../../../environments/environment';
import { ILoginRequest, ILoginResponse, IValidate2FACode } from '../interfaces/login';
import { IRegisterRequest, IRegisterResponse } from '../interfaces/register';
import { ILogoutUser, IUser } from '../interfaces/user';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { IForgetPassword, IForgetPasswordRequest } from '../interfaces/forgetPassword';
import { IResetPassword, IResetPasswordRequest } from '../interfaces/resetPassword';
import { Router } from '@angular/router';
import { IConfirmEmailResponse } from '../interfaces/confirm-code';
import { IChangePasswordRequest, IValidate2FAResponse, IsendTwoFaCodeResponse } from '../interfaces/auth-settings';

@Injectable({
  providedIn: 'root',
})

export class AuthService {

  // this is the BehaviorSubject that has all user information like avatar, username
  // public userShop: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(null);

  // using this to show confirm message in navbar that user is logged in
  public isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isPasswordChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // if true then main home will run direciton function
  public logoutState:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // if geting user data or refreshing the token
  public isGettingUserData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  private _CookieService = inject(SsrCookieService);
  private _HttpClient = inject(HttpClient);
  private _Router = inject(Router);

  // Forget Password
  forgetPassword(formData: IForgetPassword): Observable<IForgetPasswordRequest> {
    return this._HttpClient.post<IForgetPasswordRequest>(`${environment.endPointUrl}/v1/auth/reset-password`, formData);
  }

  // Reset Password
  resetPassword(formData: IResetPassword): Observable<IResetPasswordRequest> {
    return this._HttpClient.post<IResetPasswordRequest>(`${environment.endPointUrl}/v1/auth/verify-reset-password`, formData);
  }

  // change password
  changePassword(formData: IChangePasswordRequest): Observable<any> {
    return this._HttpClient.post<any>(`${environment.endPointUrl}/v1/user/change-password`, formData);
  }

  // register new user
  registerNewUser(formData: IRegisterRequest): Observable<IRegisterResponse> {
    return this._HttpClient.post<IRegisterResponse>(`${environment.endPointUrl}/v1/auth/signup`, formData);
  }

  // login
  loginUser(formData: ILoginRequest): Observable<ILoginResponse> {
    return this._HttpClient.post<ILoginResponse>(`${environment.endPointUrl}/v1/auth/login`, formData);
  }

  // get user info example: name, avatar
  getUserInfo(): Observable<IUser> {
    return this._HttpClient.get<IUser>(`${environment.endPointUrl}/v1/user/validate`);
  }

  // start Token

  // if token is expired use this method to refresh it
  freshToken(): Observable<any> {
    let refToken = this._CookieService.get('tokenUserRefresh');
    return this._HttpClient.post(`${environment.endPointUrl}/v1/auth/refresh/${refToken}`, {});
  }

  freshTheToken() {

    this.freshToken().subscribe({
      next: (res) => {
        // here there is duplicate happens so we need to delete all first
        this._CookieService.delete('tokenUser', '/')
        this._CookieService.delete('tokenUserRefresh', '/')
        // then fill the new data from the response
        this._CookieService.set('tokenUser', res.accessToken, undefined, '/');
        this._CookieService.set('tokenUserRefresh', res.refreshToken, undefined, '/');
        // after getting the new Token using tokenUserRefresh  - you need to get the userInfo again by it
        console.log("%c token refreshed now", 'background: #222; color: #bada55');
        // redirect to home then hide the loading screen
        // alert to tell that client session is refreshed due to security reason

        // if email is verified
        if (this.getLoggedInUserInfo.isEmailVerified) {
          // This What Gives Unauthorized
          this.validateUser();
        }
      },
      error: (err) => {
        console.log('err: ', err);

      }
    });

  }

  // End Token

  // get the user information by token
  validateUser() {
    this.isGettingUserData.next(true)

    this.getUserInfo().subscribe({
      next: (response) => {
        // delete all user data except token and refreshToken
        this._CookieService.delete('isEmailVerified', '/')
        this._CookieService.delete('hashId', '/')
        this._CookieService.delete('userId', '/')
        this._CookieService.delete('userInfo', '/')
        // assign it again
        if (response.isEmailVerified) {
          this._CookieService.set('isEmailVerified', `${response.isEmailVerified}`, undefined, '/')
          this.setLoggedInUserInfo = response; // update the userinfo in auth service
        } else {
          this._CookieService.set('isEmailVerified', `${response.isEmailVerified}`, undefined, '/')
        }
        this.isGettingUserData.next(false);
      },
      error: (err) => {
        if (err.status == 503) {
          this._Router.navigate(['/maintainance']);
        }
        this.isGettingUserData.next(false)
        // 401 error will be handled in interceptor
        // nothing because the error interceptor will hande this error as its 401
      }
    })
  }

  // start 2fa Authentication

  // get TwoFa Secrets - user
  getTwoFaSecrets(): Observable<any> {
    return this._HttpClient.patch(`${environment.endPointUrl}/v1/user/2fa`, {});
  }

  // send 2fa Code to validate - user - works in [login] and [enable 2fa]
  sendVerifyCode(formData: IValidate2FACode): Observable<any> {
    return this._HttpClient.post<any>(`${environment.endPointUrl}/v1/user/validate-2fa/${formData.code}`, {});
  }

  // to disable the 2fa authentication way
  disableTwoFaAuth(): Observable<IValidate2FAResponse> {
    return this._HttpClient.patch<IValidate2FAResponse>(`${environment.endPointUrl}/v1/user/disable-2fa`, {});
  }

  // 2fa Code to confirm user to login
  sendTwoFaCode(formData: any): Observable<IsendTwoFaCodeResponse> {
    return this._HttpClient.get<IsendTwoFaCodeResponse>(`${environment.endPointUrl}/v1/auth/validate-2fa/${formData.userHash}/${formData.twofaCode}`);
  }

  // End 2fa Authentication

  logout(): Observable<ILogoutUser> {
    return this._HttpClient.post<ILogoutUser>(`${environment.endPointUrl}/v1/auth/logout`,
      {
        'refreshToken': `${this._CookieService.get('tokenUserRefresh')}`
      });
  }

  logoutUser() {
    if (this._CookieService.get('tokenUserRefresh')) {
      this.logout().subscribe({
        next: (res) => { },
        error: (err) => {
          /*
            if there is error means two cases
            1- refreshtoken does not exist
            2- refreshtoken is expired

            we will delete them for now

          */

        }
      });
    }

    this.resetUserData();
    this._Router.navigate(['/home']);
  }

  resetUserData() {
    this.logoutState.next(false);
    // this._CookieService.deleteAll('/')
    this._CookieService.delete('tokenUser', '/')
    this._CookieService.delete('tokenUserRefresh', '/')
    this._CookieService.delete('isEmailVerified', '/')
    this._CookieService.delete('hashId', '/')
    this._CookieService.delete('userId', '/')
    this._CookieService.delete('userInfo', '/')
    this._CookieService.delete('2FAEnabled', '/')
  }

  // to send and email to the user who logged in with verification code
  sendVerificationEmailToUser(): Observable<IConfirmEmailResponse> {
    return this._HttpClient.patch<IConfirmEmailResponse>(`${environment.endPointUrl}/v1/user/send-verification-email`, {});
  }

  // to send the verification code
  confirmEmail(verificationCode: string): Observable<IConfirmEmailResponse> {
    return this._HttpClient.patch<IConfirmEmailResponse>(`${environment.endPointUrl}/v1/user/verify-email/${verificationCode}`, {});
  }

  // send verification email
  sendConfirmEmail(email?: string) {
    this.sendVerificationEmailToUser().subscribe({
      next: (res) => {

        this._Router.navigate(['/auth/confirm-email/']);
      },
      error: (err) => {

      }
    });
  }

  // upload new avatar string url for user
  uploadNewAvatar(avatar: string): Observable<ILoginResponse> {
    return this._HttpClient.patch<ILoginResponse>(`${environment.endPointUrl}/v1/user/change-avatar`, { "avatar": avatar });
  }

  // to set the userInfo in cookies
  set setLoggedInUserInfo(user: ILoginResponse) {
    this._CookieService.set('userInfo', JSON.stringify(user), undefined, '/');
  }

  // to get the userInfo in cookies
  get getLoggedInUserInfo() {
    if (this._CookieService.get('userInfo')) {
      return JSON.parse(this._CookieService.get('userInfo'))
    } else {
      return null;
    }
  }

  public get userToken(): string {
    return this._CookieService.get('tokenUser')!;
  }
}
